import { timeHeader } from '@/helpers/ag-grid/headerFormats'

export default [
    {
        headerName: null,
        checkboxSelection: true,
        pinned: 'left',
        width: 70,
    },
    {
        headerName: 'First Name',
        field: 'firstName',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Last Name',
        field: 'lastName',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Active',
        field: 'isActive',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Staff Id',
        field: 'staffId',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Email Address',
        field: 'emailAddress',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Id',
        field: 'id',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'City',
        field: 'city',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'State',
        field: 'state',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Username',
        field: 'username',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Title',
        field: 'title',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Staff HR Number',
        field: 'staffHRNumber',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Illuminate Account ID',
        field: 'authXAccountId',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: timeHeader('Last Updated Date'),
        field: 'updateDate',
        filter: 'date',
    },
    {
        headerName: 'SSO Id',
        field: 'sSOId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
]
